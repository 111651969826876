import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, navigate } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import ReactPaginate from 'react-paginate';

import useQuery from 'hooks/useQuery';
import Layout from 'components/Layout';
import Container from 'components/Container';
import PostPreview from 'components/blog/PostPreview';
import './Index.module.css';

const SearchInput = ({ intl }) => {
  const { q } = useQuery();
  const [value, setValue] = useState(q);

  function handleSubmit(event) {
    event.preventDefault();

    // Set query param
    // Submit query
  }

  function handleChange(event) {
    setValue(event.target.value);
  }

  return (
    <div styleName="search-input">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: 'blog.search-placeholder' })}
        />
      </form>
    </div>
  );
};

SearchInput.propTypes = {
  intl: intlShape.isRequired,
};

function BlogIndex({ intl, data, pageContext }) {
  return (
    <Layout>
      <Container>
        <div styleName="blog">
          <header styleName="header">
            <h1>
              {intl.formatMessage({ id: 'blog.title' })}
            </h1>
          </header>

          <div styleName="content-container">
            {/* Only show the 'featured post' on the first page */}
            {pageContext.pageNumber === 1 && (
              <PostPreview
                isHero
                post={data.allWpPost.nodes[0]}
              />
            )}

            <div styleName="content">
              {data.allWpPost.nodes.slice(pageContext.pageNumber === 1 ? 1 : 0).map((post) => (
                <PostPreview post={post} key={post.uri} />
              ))}
            </div>
          </div>
        </div>

        {pageContext && pageContext.totalPages > 1 && (
          <ReactPaginate
            containerClassName="pagination"
            nextLabel={intl.formatMessage({ id: 'blog.next' })}
            previousLabel={intl.formatMessage({ id: 'blog.previous' })}
            pageCount={pageContext.totalPages}
            marginPagesDisplayed={1}
            initialPage={pageContext.pageNumber - 1}
            disableInitialCallback
            onPageChange={({ selected, ...ass }) => {
              const page = selected + 1;
              const path = page === 1 ? '/blog/' : `/blog/page/${page}/`;
              navigate(path);
            }}
          />
        )}
      </Container>
    </Layout>
  );
}

BlogIndex.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(BlogIndex);

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: {
          node: { databaseId: { eq: $userDatabaseId } }
        }
        categories: {
          nodes: { elemMatch: { slug: { ne: "newscard" } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`;
